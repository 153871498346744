/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { EmailAddressSchema, PersonalNameSchema, PhoneNumberSchema, URLSchema, VerificationCodeSchema } from '.';
import { validateUserPassword } from '../validator';
import { ResidentialAddressSchema } from './address';
import {
    AuthenticationStates,
    AuthenticationStatesSchema,
    DoctorPractitionerSchema,
    PatientRegistrationSourceSchema,
} from '../constants';
import { maybe } from '../utils/runtypes';

export const LoginRequestSchema = r.Record({
    password: r.String,
    email: EmailAddressSchema,
});
export type LoginRequest = r.Static<typeof LoginRequestSchema>;

export const PatientRegistrationSchema = r.Record({
    email: EmailAddressSchema,
    last_name: PersonalNameSchema,
    first_name: PersonalNameSchema,
    source: maybe(PatientRegistrationSourceSchema),
    password: r.String.withConstraint(validateUserPassword),
});
export type PatientRegistration = r.Static<typeof PatientRegistrationSchema>;

export const DoctorRegistrationSchema = r.Record({
    phone: r.String,
    password: r.String,
    signature: URLSchema,
    qualification: r.String,
    email: EmailAddressSchema,
    provider_number: r.String,
    prescriber_number: r.String,
    last_name: PersonalNameSchema,
    first_name: PersonalNameSchema,
    address: ResidentialAddressSchema,
    practitioner_type: DoctorPractitionerSchema,
});
export type DoctorRegistration = r.Static<typeof DoctorRegistrationSchema>;

export const RecoverAccountRequestSchema = r.Record({
    email: EmailAddressSchema,
});
export type RecoverAccountRequest = r.Static<typeof RecoverAccountRequestSchema>;

export const VerifyAccountRequestSchema = r.Record({
    code: VerificationCodeSchema,
});
export type VerifyAccountRequest = r.Static<typeof VerifyAccountRequestSchema>;

export const VerifyRecoveryTokenResponseSchema = r.Record({
    email: EmailAddressSchema,
});
export type VerifyRecoveryTokenResponse = r.Static<typeof VerifyRecoveryTokenResponseSchema>;

export const ResetPasswordSchema = r.Record({
    password: r.String.withConstraint(validateUserPassword),
});
export type ResetPassword = r.Static<typeof ResetPasswordSchema>;

// Express authentication types
export const VerifyExpressAccountSchema = r.Record({
    phone: PhoneNumberSchema,
    email: EmailAddressSchema,
});
export type VerifyExpressAccount = r.Static<typeof VerifyExpressAccountSchema>;

export const VerifyExpressAccountStandardResponseSchema = r.Record({
    email: EmailAddressSchema,
    state: r.Literal(AuthenticationStates.EmailPassword),
});

export const VerifyExpressAccountOTPResponseSchema = r.Record({
    phone: r.String,
    state: r.Literal(AuthenticationStates.OneTimePassword),
});

export const VerifyExpressAccountExpressResponseSchema = r.Record({
    state: r.Literal(AuthenticationStates.Express),
});

export const VerifyExpressAccountResponseSchema = VerifyExpressAccountStandardResponseSchema.Or(
    VerifyExpressAccountOTPResponseSchema,
).Or(VerifyExpressAccountExpressResponseSchema);
export type VerifyExpressAccountResponse = r.Static<typeof VerifyExpressAccountResponseSchema>;
