/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { DigitalConsultationSchema, PhoneNumberSchema } from '.';
import { AlternativeTreatmentServiceSchema } from '../constants';
import { ServiceRequestType } from '../constants/checkout';

const AlternativeMedicineDetailsSchema = r.Record({
    phone: PhoneNumberSchema,
});

export const AlternativeMedicineRequestSchema = r.Record({
    title: AlternativeTreatmentServiceSchema,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.AlternativeMedicine),
    consultationDetails: AlternativeMedicineDetailsSchema,
});
export type AlternativeMedicineRequest = r.Static<typeof AlternativeMedicineRequestSchema>;
