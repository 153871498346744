/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { DigitalConsultationSchema } from '..';
import { ServiceRequestType } from '../../constants/checkout';
import { CarersPatientSchema, MedicalCertificatesTypes } from '../../constants';

export const GeneralMedicalCertificateTitleSchema = r
    .Literal(MedicalCertificatesTypes.SingleDayCertificateForSchoolOrUniversity)
    .Or(r.Literal(MedicalCertificatesTypes.SingleDayCertificateForWork))
    .Or(r.Literal(MedicalCertificatesTypes.MultiDayCertificate));

export const GeneralMedicalCertificateRequestSchema = r.Record({
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.MedicalCertificate),
    title: GeneralMedicalCertificateTitleSchema,
});
export type GeneralMedicalCertificateRequest = r.Static<typeof GeneralMedicalCertificateRequestSchema>;

export const CarersMedicalCertificateRequestSchema = r.Record({
    patient: CarersPatientSchema,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.MedicalCertificate),
    title: r.Literal(MedicalCertificatesTypes.SingleDayCertificateForCarersLeave),
});
export type CarersMedicalCertificateRequest = r.Static<typeof CarersMedicalCertificateRequestSchema>;

export const MedicalCertificateRequestSchema = GeneralMedicalCertificateRequestSchema.Or(
    CarersMedicalCertificateRequestSchema,
);
export type MedicalCertificateRequest = r.Static<typeof MedicalCertificateRequestSchema>;
