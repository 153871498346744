/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export const PATIENT_FLAG_MESSAGE_LIMIT = 620;

export enum MedicalRequestType {
    AutomatedScript = 'Automated Script',
    GeneralPractice = 'General Practice',
}
export const MedicalRequestTypeSchema = runtypeFromEnum(MedicalRequestType);

export enum PatientWaitingTime {
    New = 'New',
    Pending = 'Pending',
    Stale = 'Stale',
}

export enum MedicalRequestSeverityLevel {
    Information = 'Information',
    Warning = 'Warning',
    Consultation = 'Consultation',
    Alert = 'Alert',
}
export const MedicalRequestSeverityLevelSchema = runtypeFromEnum(MedicalRequestSeverityLevel);

export enum MedicalRequestStatus {
    Picked = 'Picked',
    Waiting = 'Waiting',
    DidNotAnswer = 'Did Not Answer',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
    Completed = 'Completed',
    DigitalConsultation = 'Digital Consultation',
    MedicalProfileMissing = 'Medical Profile Missing',
}
export const MedicalRequestStatusSchema = runtypeFromEnum(MedicalRequestStatus);

export enum CommunicationType {
    Phone = 'Phone',
    Video = 'Video',
}
export const CommunicationTypeSchema = runtypeFromEnum(CommunicationType);

export enum CarersPatient {
    ChildParent = 'Child or Parent',
    Partner = 'Partner',
}
export const CarersPatientSchema = runtypeFromEnum(CarersPatient);

export enum MedicalRequestScriptDocumentType {
    Referral = 'Referral',
    Pathology = 'Pathology',
    Telehealth = 'Telehealth',
    SleepStudy = 'Sleep Study',
    Prescription = 'Prescription',
    DoctorLetter = 'Doctor Letter',
    MentalHealth = 'Mental Health',
    HairConsultation = 'Hair Consultation',
    MedicalCertificate = 'Medical Certificate',
    MedicalImagingOrRadiology = 'Medical Imaging Or Radiology',
}
export const MedicalRequestScriptDocumentTypeSchema = runtypeFromEnum(MedicalRequestScriptDocumentType);

const LETTER_REJECTION_NOTE = `Thank you for your request with Aussie Scripts. Unfortunately, we are not able to provide the requested treatment. We recommend booking an in-person consult with your regular doctor to discuss your options further.`;

const PRESCRIPTION_REJECTION_GP_NOTE = `Thank you for your request with Aussie Scripts. Unfortunately, we are not able to provide the requested treatment. We recommend booking an in-person consult with your regular doctor to discuss your options further.`;

const PRESCRIPTION_REJECTION_APP_NOTE = `Thank you for your request with Aussie Scripts. Unfortunately, we are unable to provide the requested treatment. We recommend booking a consultation with one of our doctors by clicking the button below or visiting our telehealth consultation page.`;

export function generateLetterScriptRejectionNote(serviceTitle: string, doctorNotes: string) {
    return `${LETTER_REJECTION_NOTE} <br/> <br/><strong>Request: <span style="color: #6941c6">${serviceTitle}</span> </strong> <br/> <br/>${doctorNotes}`;
}

export function generatePrescriptionRejectionNote(doctorNotes: string, isGeneralConsultation: boolean) {
    return isGeneralConsultation
        ? `${PRESCRIPTION_REJECTION_GP_NOTE} <br/> <br/><strong>Request: <span style="color: #6941c6">Online Prescription</span> </strong> <br/> <br/>${doctorNotes}<br/>`
        : `${PRESCRIPTION_REJECTION_APP_NOTE} <br/> <br/><strong>Request: <span style="color: #6941c6">Online Prescription</span> </strong> <br/> <br/>${doctorNotes}<br/> <br/>
    <p style="text-align: center; margin-top: 24px;"><a style="background: #6941c6; color: #fff; border-radius: 6px; padding: 12px 24px; display: block; width: 100%; line-height: 24px;" href="${process.env.PUBLIC_WEBSITE_LINK}/patient/telehealth/consultation/general">Talk To A Doctor</a></p>
    `;
}

export enum ExpressVerificationStatus {
    NewPatient = 'New Patient',
    ExistingPatient = 'Existing Patient',
}
export const ExpressVerificationStatusSchema = runtypeFromEnum(ExpressVerificationStatus);

export enum MedicalRequestServiceType {
    Express = 'Express',
    Standard = 'Standard',
}
export const MedicalRequestServiceTypeSchema = runtypeFromEnum(MedicalRequestType);

export enum ExpressMedicalResponseStatus {
    Access = 'Access',
    Verification = 'Verification',
    NotAvailable = 'Not Available',
}
